/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */
import {
    postLineitemApi,
    getCustomListApi,
    getProvidersApi,
    getAppNameLookupApi,
    getAppLookupFieldApi,
    duplicateLineitemApi,
    getRefApi,
    postLineitemTargetingApi,
    getAdvertiserCreativeListApi,
    postCreativeLineitemApi,
    postBulkCreativeLineitemApi,
    getLineitemCreativeListApi,
    getCampaignLineItemApi,
    deleteAssLineItemApi,
    deleteLineItemApi,
    deleteBulkLineItemApi,
    postLineItemFindAndReplaceApi,
    putLineitemApi,
    postStrictLineitemApi,
    getLineItemSingleDataApi,
    deleteBulkAssLineItemCreativeApi,
    getLineitemThirdpartySegmentApi,
    getLineitemOurSegmentApi,
    getLineitemTargetingApi,
    putLineitemTargetingApi,
    putLineitemStrictApi,
    getLineitemThirdpartySegmentSearchApi,
    putAssLineItemApi, postCreativeLineItemFindAndReplaceApi, isLineItemNameExistApi,
    getRealTimeStatApi,
    getLineItemsRecord,
    fetchBiddingStatFlagsApi,
    postBulkTargetingLineItemApi,
    postBidModifierApi,
    putBidModifierApi,
    getBidModifierApi,
    getSingleBidModifierApi,
    getAdvertiserCategory,
    getGenreList,
    postDeliveryModifierApi,
    postDeliveryBulkModifierApi,
    postDeliveryBulkCappingApi,
    putDeliveryModifierApi,
    getDeliveryModifierApi,
    getSingleDeliveryModifierApi,
    postDeliveryCappingsApi,
    postDeliveryBulkCappingsApi,
    putDeliveryCappingsApi,
    getDeliveryCappingsApi,
    getSingleDeliveryCappingsApi,
    postBulkBidModifierApi,
    getRillDataApi,
    postSaveQueryParamsApi,
    getQueryParamsApi
} from "../../helpers/api";

export const state = {
    saveQueryParamsSuccess:{},
    getQueryParamsSuccess:{},
    lineitemSuccess: {},
    lineitemActiveSuccess: {},
    lineitemActiveErr: {},
    lineItemSuccessWithoutRefresh: {},
    lineitemUpdateSuccess: {},
    lineItemFindAndReplace: {},
    lineitemErr: {},
    appIdsData: [],
    dealIdsData: [],
    appBundleData: [],
    providerData:[],
    providerListResponse: [],
    channelListResponse: [],
    appNameListResponse: [],
    placementData: [],
    publisherIdsData: [],
    countriesData: [],
    permissionsData: [],
    regionsData: [],
    appNameData: [],
    appNameResponse: [],
    appIdResponse: [],
    appBundleResponse: [],
    metrosData: [],
    osTypesData: [],
    citiesData: [],
    zipCodeData: [],
    latlongData: [],
    adPositionData: [],
    adsTxtData: [],
    environmentTypeData: [],
    nativeLayoutData: [],
    inventorySourceData: [],
    advertiserCreativeSuccess: [],
    languagesData: [],
    contentRatingData: [],
    contentCategoriesData: [],
    genreData: [],
    creativeSuccess: {},
    bulkCreativeSuccess: {},
    creativeListSuccess: [],
    timeZoneData: [],
    lineitemListSuccess: [],
    lineItemDeleteStatus: null,
    lineItemBulkDeleteStatus: null,
    assocLineItemCreativeBulkDeleteStatus: null,
    assLineItemDeleteStatus: null,
    lineItemAssCreativeUpdateStatus: null,
    lineitemTargetingSuccess: {},
    lineitemTargetingSingleSuccess: {},
    lineitemTargetingErr: {},
    lineItemSingleData: {},
    creativeLineItemFindAndReplace: {},
    lineItemOurSegmentSuccess: [],
    lineItemThirdpartySegmentSuccess: [],
    lineItemNameExist: null,
    duplicateLineitemSuccess: null,
    lineItemThirdpartySelectedSegmentSuccess: [],
    thirdpartySelectedSegmentGroupSuccess: [],
    isLineItemLoad: false,
    lineItemRealTimeStat: null,
    lineItemsDownload: null,
    lineItemFindAndReplaceErr: null,
    creativeLineItemFindAndReplaceErr: null,
    isExchangeRequired: false,
    creativeLineItemListSuccess: [],
    lineItemDuplicateSwitch: ['targeting_expressions', 'creative_line_items'],
    lineItemTargetListDataFlag: false,
    lineItemBiddingStatFlags: {},
    lineItemBulkTargetingStatus: {},
    bidModifierSuccess: {},
    bidModifierError: {},
    bidModifierData: {},
    bidModifierSingleData: {},
    deliveryModifierSuccess: {},
    deliveryModifierError: {},
    deliveryModifierData: {},
    deliveryModifierSingleData: {},
    deliveryModifierBulkResponse: [],
    deliveryCappingBulkResponse:[],
    deliveryCappingsSuccess: {},
    deliveryCappingsError: {},
    deliveryCappingsData: {},
    deliveryCappingsSingleData: {},
    deliveryCappingsBulkResponse: [],
    bidModifierBulkResponse: [],
    isLoading: {
        app_bundle_list: false,
        app_id_list: false,
        provider_list: false,
        app_name_list: false,
        app_name: false,
        app_id: false,
        app_bundle: false,
        provider: false,
        zip_code_list: false,
        lat_long_list: false,
        content_category: false,
        genre: false
    },
    isSearchEmpty: {
        app_bundle_list: false,
        app_id_list: false,
        provider_list: false,
        app_name_list: false,
        app_name: false,
        app_id: false,
        app_bundle: false,
        provider: false,
        zip_code_list: false,
        lat_long_list: false,
        content_category: false,
        genre: false
    },
    rillDataSuccess: {}
};

export const getters = {
    saveQueryParamsSuccess: state => state.saveQueryParamsSuccess,
    getQueryParamsSuccess: state => state.getQueryParamsSuccess,
    lineitemSuccess: state => state.lineitemSuccess,
    lineItemFindAndReplace: state => state.lineItemFindAndReplace,
    lineItemFindAndReplaceErr: state => state.lineItemFindAndReplaceErr,
    lineitemUpdateSuccess: state => state.lineitemUpdateSuccess,
    lineitemListSuccess: state => state.lineitemListSuccess,
    lineitemTargetingSuccess: state => state.lineitemTargetingSuccess,
    lineitemTargetingSingleSuccess: state => state.lineitemTargetingSingleSuccess,
    lineitemTargetingErr: state => state.lineitemTargetingErr,
    lineitemErr: state => state.lineitemErr,
    appIdsData: state => state.appIdsData,
    dealIdsData: state => state.dealIdsData,
    providerData: state => state.providerData,
    providerListResponse: state => state.providerListResponse,
    channelListResponse: state => state.channelListResponse,
    appNameListResponse: state => state.appNameListResponse,
    appBundleData: state => state.appBundleData,
    placementData: state => state.placementData,
    publisherIdsData: state => state.publisherIdsData,
    countriesData: state => state.countriesData,
    regionsData: state => state.regionsData,
    metrosData: state => state.metrosData,
    osTypesData: state => state.osTypesData,
    citiesData: state => state.citiesData,
    zipCodeData: state => state.zipCodeData,
    latlongData: state => state.latlongData,
    adPositionData: state => state.adPositionData,
    adsTxtData: state => state.adsTxtData,
    environmentTypeData: state => state.environmentTypeData,
    nativeLayoutData: state => state.nativeLayoutData,
    timeZoneData: (state) => state.timeZoneData,
    permissionsData: state => state.permissionsData,
    inventorySourceData: state => state.inventorySourceData,
    languagesData: state => state.languagesData,
    contentRatingData: state => state.contentRatingData,
    contentCategoriesData: state => state.contentCategoriesData,
    genreData: state => state.genreData,
    advertiserCreativeSuccess: (state) => state.advertiserCreativeSuccess,
    creativeSuccess: (state) => state.creativeSuccess,
    bulkCreativeSuccess: (state) => state.bulkCreativeSuccess,
    creativeListSuccess: (state) => state.creativeListSuccess,
    lineItemDeleteStatus: (state) => state.lineItemDeleteStatus,
    lineItemBulkDeleteStatus: (state) => state.lineItemBulkDeleteStatus,
    assocLineItemCreativeBulkDeleteStatus: (state) => state.assocLineItemCreativeBulkDeleteStatus,
    assLineItemDeleteStatus: (state) => state.assLineItemDeleteStatus,
    lineItemSingleData: (state) => state.lineItemSingleData,
    lineItemOurSegmentSuccess: (state) => state.lineItemOurSegmentSuccess,
    lineItemThirdpartySegmentSuccess: (state) => state.lineItemThirdpartySegmentSuccess,
    lineItemAssCreativeUpdateStatus: (state) => state.lineItemAssCreativeUpdateStatus,
    creativeLineItemFindAndReplace: (state) => state.creativeLineItemFindAndReplace,
    creativeLineItemFindAndReplaceErr: (state) => state.creativeLineItemFindAndReplaceErr,
    lineItemNameExist: (state) => state.lineItemNameExist,
    duplicateLineitemSuccess: state => state.duplicateLineitemSuccess,
    lineItemThirdpartySelectedSegmentSuccess: state => state.lineItemThirdpartySelectedSegmentSuccess,
    appNameData: (state) => state.appNameData,
    appNameResponse: (state) => state.appNameResponse,
    appIdResponse: (state) => state.appIdResponse,
    appBundleResponse: (state) => state.appBundleResponse,
    isLineItemLoad: (state) => state.isLineItemLoad,
    isExchangeRequired: (state) => state.isExchangeRequired,
    lineItemRealTimeStat: state => state.lineItemRealTimeStat,
    lineItemSuccessWithoutRefresh: state => state.lineItemSuccessWithoutRefresh,
    creativeLineItemListSuccess: state => state.creativeLineItemListSuccess,
    lineItemsDownload: state => state.lineItemsDownload,
    lineItemDuplicateSwitch: state => state.lineItemDuplicateSwitch,
    lineItemTargetListDataFlag: state => state.lineItemTargetListDataFlag,
    lineItemBiddingStatFlags: state => state.lineItemBiddingStatFlags,
    lineItemBulkTargetingStatus: state => state.lineItemBulkTargetingStatus,
    lineitemActiveSuccess: state => state.lineitemActiveSuccess,
    lineitemActiveErr: state => state.lineitemActiveErr,
    bidModifierSuccess: state => state.bidModifierSuccess,
    bidModifierError: state => state.bidModifierError,
    bidModifierData: state => state.bidModifierData,
    bidModifierSingleData: state => state.bidModifierSingleData,
    deliveryModifierSuccess: state => state.deliveryModifierSuccess,
    deliveryModifierBulkResponse: state => state.deliveryModifierBulkResponse,
    deliveryModifierError: state => state.deliveryModifierError,
    deliveryModifierData: state => state.deliveryModifierData,
    deliveryModifierSingleData: state => state.deliveryModifierSingleData,
    deliveryCappingsSuccess: state => state.deliveryCappingsSuccess,
    deliveryCappingsError: state => state.deliveryCappingsError,
    deliveryCappingsData: state => state.deliveryCappingsData,
    deliveryCappingsBulkResponse: state => state.deliveryCappingsBulkResponse,
    deliveryCappingsSingleData: state => state.deliveryCappingsSingleData,
    rillDataSuccess: state => state.rillDataSuccess,
    isLoading: state => state.isLoading,
    isSearchEmpty: state => state.isSearchEmpty
}

export const mutations =
    {
        setAdvertiserCreativeSuccess(state, newValue) {
            state.advertiserCreativeSuccess = newValue
        }
        ,
        setLineItemFindAndReplace(state, newValue) {
            state.lineItemFindAndReplace = newValue
        }
        ,
        setLineItemFindAndReplaceErr(state, newValue) {
            state.lineItemFindAndReplaceErr = newValue
        }
        ,
        setCreativeSuccess(state, newValue) {
            state.creativeSuccess = newValue
        }
        ,
        setBulkCreativeSuccess(state, newValue) {
            state.bulkCreativeSuccess = newValue
        },
        setLineitemErr(state, newValue) {
            state.lineitemErr = newValue
        }
        ,
        setSaveQueryParamsSuccess(state, newValue) {
            state.saveQueryParamsSuccess = newValue
        },
        setGetQueryParamsSuccess(state, newValue) {
            state.setGetQueryParamsSuccess = newValue
        },
        
        setLineitemSuccess(state, newValue) {
            state.lineitemSuccess = newValue
        },
        setLineitemActiveSuccess(state, newValue) {
            state.lineitemActiveSuccess = newValue
        },
        setLineitemActiveErr(state, newValue) {
            state.lineitemActiveErr = newValue
        },
        setDuplicateLineitemSuccess(state, newValue) {
            state.duplicateLineitemSuccess = newValue
        }
        ,
        setLineitemUpdateSuccess(state, newValue) {
            state.lineitemUpdateSuccess = newValue
        }
        ,
        setLineitemTargetingSuccess(state, newValue) {
            state.lineitemTargetingSuccess = newValue
        }
        ,
        setLineitemTargetingSingleSuccess(state, newValue) {
            state.lineitemTargetingSingleSuccess = newValue
        }
        ,
        setLineitemTargetingErr(state, newValue) {
            state.lineitemTargetingErr = newValue
        }
        ,
        setAppIdsData(state, newValue) {
            state.appIdsData = newValue
        }
        ,
        setDealIdsData(state, newValue) {
            state.dealIdsData = newValue
        },
        setProviderData(state, newValue) {
            state.providerData = newValue
        },

        setProviderListResponse(state, newValue) {
            state.providerListResponse = newValue
        },
        setChannelListResponse(state, newValue) {
            state.channelListResponse = newValue
        },
        setAppNameListResponse(state, newValue) {
            state.appNameListResponse = newValue
        },
        
        setPublisherIdsData(state, newValue) {
            state.publisherIdsData = newValue
        }
        ,
        setAppBundleData(state, newValue) {
            state.appBundleData = newValue
        }
        ,
        setPlacementData(state, newValue) {
            state.placementData = newValue
        }
        ,
        setCountriesData(state, newValue) {
            state.countriesData = newValue
        }
        ,
        setRegionsData(state, newValue) {
            state.regionsData = newValue
        }
        ,
        setMetrosData(state, newValue) {
            state.metrosData = newValue
        }
        ,
        setOsTypesData(state, newValue) {
            state.osTypesData = newValue
        }
        ,
        setCitiesData(state, newValue) {
            state.citiesData = newValue
        },
        setPermissionsData(state, newValue) {
            state.permissionsData = newValue
        }
        ,
        setZipCodeData(state, newValue) {
            state.zipCodeData = newValue
        }
        ,
        setLatLongData(state, newValue) {
            state.latlongData = newValue
        }
        ,
        setAdPositionData(state, newValue) {
            state.adPositionData = newValue
        }
        ,
        setAdsTxtData(state, newValue) {
            state.adsTxtData = newValue
        }
        ,
        setEnvironmentTypeData(state, newValue) {
            state.environmentTypeData = newValue
        }
        ,
        setNativeLayoutData(state, newValue) {
            state.nativeLayoutData = newValue
        }
        ,
        setInventorySourceData(state, newValue) {
            state.inventorySourceData = newValue
        }
        ,
        setLanguagesData(state, newValue) {
            state.languagesData = newValue
        },
        setContentRatingData(state, newValue) {
            state.contentRatingData = newValue
        },
        setContentCategoriesData(state, newValue) {
            state.contentCategoriesData = newValue
        },
        setGenreData(state, newValue) {
            state.genreData = newValue
        },
        setTimeZoneData(state, newValue) {
            state.timeZoneData = newValue
        },
        setLineItemCreativeSuccess(state, newValue) {
            state.creativeListSuccess = newValue
        },
        setCreativeLineItemSuccess(state, newValue) {
            state.creativeLineItemListSuccess = newValue
        }
        ,
        setLineitemListSuccess(state, newValue) {
            state.lineitemListSuccess = newValue
        }
        ,
        setLineItemDeleteStatus(state, newValue) {
            state.lineItemDeleteStatus = newValue
        }
        ,
        setBulkLineItemDeleteStatus(state, newValue) {
            state.lineItemBulkDeleteStatus = newValue
        }
        ,
        setBulkLineItemCreativeDeleteStatus(state, newValue) {
            state.assocLineItemCreativeBulkDeleteStatus = newValue
        }
        ,
        setLineItemAssDeleteStatus(state, newValue) {
            state.assLineItemDeleteStatus = newValue
        }
        ,
        setLineItemAssCreativeUpdateStatus(state, newValue) {
            state.lineItemAssCreativeUpdateStatus = newValue
        }
        ,
        setLineItemSingleDataSuccess(state, newValue) {
            state.lineItemSingleData = newValue
        }
        ,
        setLineItemOurSegmentSuccess(state, newValue) {
            state.lineItemOurSegmentSuccess = newValue
        },
        setLineItemThirdpartySegmentSuccess(state, newValue) {
            state.lineItemThirdpartySegmentSuccess = newValue
        },
        setLineItemThirdpartySelectedSegmentSuccess(state, newValue) {
            state.lineItemThirdpartySelectedSegmentSuccess = newValue
        },
        setThirdpartySelectedSegmentGroupSuccess(state, newValue) {
            state.thirdpartySelectedSegmentSuccess = newValue
        },
        setCreativeLineItemFindAndReplace(state, newValue) {
            state.creativeLineItemFindAndReplace = newValue
        },
        setCreativeLineItemFindAndReplaceErr(state, newValue) {
            state.creativeLineItemFindAndReplaceErr = newValue
        },
        setLineItemNameExist(state, newValue) {
            state.lineItemNameExist = newValue
        },
        setAppNameData(state, newValue) {
            state.appNameData = newValue
        },

        setAppNameResponse(state, newValue) {
            state.appNameResponse = newValue
        },
        setAppIdResponse(state, newValue) {
            state.appIdResponse = newValue
        },
        setAppBundleResponse(state, newValue) {
            state.appBundleResponse = newValue
        },
        setLineItemLoadFlag(state, newVal) {
            state.isLineItemLoad = newVal;
        },
        setLineItemRealTimeStat(state, newValue) {
            state.lineItemRealTimeStat = newValue
        },
        setIsExchangeRequiredFlag(state, newValue) {
            state.isExchangeRequired = newValue
        },
        setLineItemSuccessWithoutRefresh(state, newValue) {
            state.lineItemSuccessWithoutRefresh = newValue
        },
        setExportLineItemRecord(state, newValue) {
            state.lineItemsDownload = newValue
        },
        setLineItemDuplicateSwitch(state, newValue) {
            state.lineItemDuplicateSwitch = newValue
        },
        setLineItemTargetListDataFlag(state, newValue) {
            state.lineItemTargetListDataFlag = newValue
        },
        setLineItemBiddingStatFlags(state, newValue) {
            state.lineItemBiddingStatFlags = newValue
        },
        setLineItemBulkTargetingStatus(state, newValue) {
            state.lineItemBulkTargetingStatus = newValue
        },
        setBidModifierSuccess(state, newValue) {
            state.bidModifierSuccess = newValue
        },
        setBidModifierError(state, newValue) {
            state.bidModifierError = newValue
        },
        setBidModifierData(state, newValue) {
            state.bidModifierData = newValue
        },
        setSingleBidModifierData(state, newValue) {
            console.log('setSingleBidModifierData',newValue)
            state.bidModifierSingleData = newValue
        },
        setDeliveryModifierSuccess(state, newValue) {
            state.deliveryModifierSuccess = newValue
        },
        setDeliveryModifierBulkResponse(state, newValue) {
            state.deliveryModifierBulkResponse = newValue
        },
        setDeliveryCappingBulkResponse(state, newValue) {
            state.deliveryCappingBulkResponse = newValue
        },
        setBidModifierBulkResponse(state, newValue) {
            state.bidModifierBulkResponse = newValue
        },
        setDeliveryModifierError(state, newValue) {
            state.deliveryModifierError = newValue
        },
        setDeliveryModifierData(state, newValue) {
            state.deliveryModifierData = newValue
        },
        setSingleDeliveryModifierData(state, newValue) {
            state.deliveryModifierSingleData = newValue
        },
        setDeliveryCappingsSuccess(state, newValue) {
            state.deliveryCappingsSuccess = newValue
        },
        setSingleDeliveryCappingsData(state, newValue) {
            state.deliveryCappingsSingleData = newValue
        },
        setDeliveryCappingsData(state, newValue) {
            
            state.deliveryCappingsData = newValue
        },
        setDeliveryCappingsError(state, newValue) {
            state.deliveryCappingsError = newValue
        },
        setRillDataSuccess(state, newValue) {
            state.rillDataSuccess = newValue
        },
        setLoading(state, newValue) {
            // Update the loading status for a specific list type
            let obj = {};
            obj[newValue.listType] = newValue.isLoading
            state.isLoading = {...state.isLoading , ...obj};
        },
        setSearchDataEmpty(state, newValue) {
            // Update the Search Empty for a specific list type
            let obj = {};
            obj[newValue.listType] = newValue.isSearchEmpty
            state.isSearchEmpty = {...state.isSearchEmpty, ...obj};
        },
    };

export const actions =
    {
        postBidModifier({commit}, payload) {
            postBidModifierApi(payload).then(res => {
                commit('setBidModifierSuccess', res.data)
            }, (error) => {
                commit('setBidModifierError', error.data)
            })
        },
        fetchRillData({commit}, payload) {
            getRillDataApi(payload).then(res => {
                commit('setRillDataSuccess', res.data)
            }, (error) => {
                commit('setRillDataSuccess', {})
            })
        },
        putBidModifier({commit}, payload) {
            putBidModifierApi(payload).then(res => {
                commit('setBidModifierSuccess', res.data)
            }, (error) => {
                commit('setBidModifierError', error.data)
            })
        },
        getBidModifier({commit}, payload) {
            getBidModifierApi(payload).then(res => {
                commit('setBidModifierData', res.data)
            }, (error) => {
                commit('setBidModifierData', {})
                commit('setBidModifierError', error.data)
            })
        },
        getSingleBidModifier({commit}, payload) {
            getSingleBidModifierApi(payload).then(res => {
                console.log('singleBidModifierData payload',payload,res.data)
                commit('setSingleBidModifierData', res.data)
            }, (error) => {
                commit('setSingleBidModifierData', {})
            })
        },
        postDeliveryModifier({commit}, payload) {
            postDeliveryModifierApi(payload).then(res => {
                commit('setDeliveryModifierSuccess', res.data)
            }, (error) => {
                commit('setDeliveryModifierError', error.data)
            })
        },
        postDeliveryBulkModifier({commit}, payload) {
            postDeliveryBulkModifierApi(payload).then(res => {
                commit('setDeliveryModifierBulkResponse', res.data)
            }, (error) => {
                commit('setDeliveryModifierBulkResponse', error.data)
            })
        },
        postDeliveryBulkCapping({commit}, payload) {
            postDeliveryBulkCappingApi(payload).then(res => {
                commit('setDeliveryCappingBulkResponse', res.data)
            }, (error) => {
                commit('setDeliveryCappingBulkResponse', error.data)
            })
        },

        postBulkBidModifier({commit}, payload) {
            postBulkBidModifierApi(payload).then(res => {
                commit('setBidModifierBulkResponse', res.data)
            }, (error) => {
                commit('setBidModifierBulkResponse', error.data)
            })
        },
        
        putDeliveryModifier({commit}, payload) {
            putDeliveryModifierApi(payload).then(res => {
                commit('setDeliveryModifierSuccess', res.data)
            }, (error) => {
                commit('setDeliveryModifierError', error.data)
            })
        },
        getDeliveryModifier({commit}, payload) {
            getDeliveryModifierApi(payload).then(res => {
                commit('setDeliveryModifierData', res.data)
            }, (error) => {
                commit('setDeliveryModifierData', {})
                commit('setDeliveryModifierError', error.data)
            })
        },
        getSingleDeliveryModifier({commit}, payload) {
            getSingleDeliveryModifierApi(payload).then(res => {
                commit('setSingleDeliveryModifierData', res.data)
            }, (error) => {
                commit('setSingleDeliveryModifierData', {})
            })
        },
        clearModifierData({commit}) {
            commit('setBidModifierSuccess', null);
            commit('setBidModifierData', null);
            commit('setSingleBidModifierData', null)
            commit('setBidModifierBulkResponse',null)
        },
        clearDeliveryModifierData({commit}) {
            commit('setDeliveryModifierSuccess', null);
            commit('setDeliveryModifierData', null);
            commit('setSingleDeliveryModifierData', null)
            commit('setDeliveryModifierBulkResponse', null)
            
        },

        postDeliveryCappings({commit}, payload) {
            postDeliveryCappingsApi(payload).then(res => {
                commit('setDeliveryCappingsSuccess', res.data)
            }, (error) => {
                commit('setDeliveryCappingsError', error.data)
            })
        },
        postDeliveryBulkCappings({commit}, payload) {
            postDeliveryBulkCappingsApi(payload).then(res => {
                commit('setDeliveryCappingsBulkResponse', res.data)
            }, (error) => {
                commit('setDeliveryCappingsBulkResponse', error.data)
            })
        },
        putDeliveryCappings({commit}, payload) {
            putDeliveryCappingsApi(payload).then(res => {
                commit('setDeliveryCappingsSuccess', res.data)
            }, (error) => {
                commit('setDeliveryCappingsError', error.data)
            })
        },
        getDeliveryCappings({commit}, payload) {
            getDeliveryCappingsApi(payload).then(res => {
                commit('setDeliveryCappingsData', res.data)
            }, (error) => {
                commit('setDeliveryCappingsData', {})
                commit('setDeliveryCappingsError', error.data)
            })
        },
        getSingleDeliveryCappings({commit}, payload) {
            getSingleDeliveryCappingsApi(payload).then(res => {
                commit('setSingleDeliveryCappingsData', res.data)
            }, (error) => {
                commit('setSingleDeliveryCappingsData', {})
            })
        },
        clearDeliveryCappingsData({commit}) {
            commit('setDeliveryCappingsSuccess', null);
            commit('setDeliveryCappingsData', null);
            commit('setSingleDeliveryCappingsData', null)
            commit('setDeliveryCappingBulkResponse', null)
            

        },
        setLineItemLoad({commit}, value) {
            commit('setLineItemLoadFlag', value);
        },
        setIsExchangeRequired({commit}, value) {
            commit('setIsExchangeRequiredFlag', value);
        },
        postLineitem({commit}, payload) {
            postLineitemApi(payload).then(res => {
                commit('setLineitemSuccess', res.data)
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        postSaveQueryParams({commit}, payload) {
            postSaveQueryParamsApi(payload).then(res => {
                commit('setSaveQueryParamsSuccess', res.data)
            }, (error) => {
                commit('setSaveQueryParamsSuccess', null)
            })
        },
        getQueryParams({commit}, payload) {
            getQueryParamsApi(payload).then(res => {
                commit('setGetQueryParamsSuccess', res.data)
            }, (error) => {
                commit('setGetQueryParamsSuccess', null)
            });
        },
        postStrictLineitem({commit}, payload) {
            postStrictLineitemApi(payload).then(res => {
                commit('setLineitemSuccess', res.data)
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        exportLineItemRecord({commit}, payload) {
            getLineItemsRecord(payload).then(res => {
                commit('setExportLineItemRecord', res.data)
            })
        },
        duplicateLineitem({commit}, payload) {
            duplicateLineitemApi(payload).then(res => {
                commit('setDuplicateLineitemSuccess', res.data)
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        putLineitem({commit}, payload) {
            putLineitemApi(payload).then(res => {
                (Object.hasOwn(payload, 'diff_state') ? commit('setLineItemSuccessWithoutRefresh', res.data) : commit('setLineitemSuccess', res.data));
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        putStrictLineItem({commit}, payload) {
            putLineitemStrictApi(payload).then(res => {
                (Object.hasOwn(payload, 'diff_state') ? commit('setLineItemSuccessWithoutRefresh', res.data) : commit('setLineitemSuccess', res.data));
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        putStrictLineItemActive({commit}, payload) {
            putLineitemStrictApi(payload).then(res => {
                commit('setLineitemActiveSuccess', res.data);
            }, (error) => {
                commit('setLineitemActiveErr', error.data)
            })
        },
        postLineitemTargeting({commit}, payload) {
            postLineitemTargetingApi(payload).then(res => {
                commit('setLineitemTargetingSuccess', res.data)
            }, (error) => {
                commit('setLineitemTargetingErr', error.data)
            });
        },
        putLineitemTargeting({commit}, payload) {
            putLineitemTargetingApi(payload).then(res => {
                commit('setLineitemTargetingSuccess', res.data)
            }, (error) => {
                commit('setLineitemTargetingErr', error.data)
            });
        },
        getLineitemTargeting({commit}, payload) {
            getLineitemTargetingApi(payload).then(res => {
                commit('setLineitemTargetingSingleSuccess', res.data)
            }, (error) => {
                commit('setLineitemTargetingSingleSuccess', null)
            });
        },
        postLineItemFindAndReplace({commit}, payload) {
            postLineItemFindAndReplaceApi(payload).then(res => {
                commit('setLineItemFindAndReplace', res.data)
            }, (error) => {
                commit('setLineItemFindAndReplaceErr', error.data)
            })
        },
        clearLineitemErr({commit}) {
            commit('setLineitemErr', null);
        },
        clearLineitem({commit}) {
            commit('setLineitemSuccess', null);
            commit('setLineitemErr', null);
            commit('setLineItemFindAndReplace', null);
            commit('setLineItemFindAndReplaceErr', null);
            commit('setLineitemTargetingErr', null);
            commit('setLineitemListSuccess', []);
            commit('setCreativeLineItemSuccess', []);
            //commit('setLineitemTargetingListSuccess', null);
            commit('setLineItemCreativeSuccess', null);
            commit('setDuplicateLineitemSuccess', null);
            commit('setCreativeLineItemFindAndReplace', null);
            commit('setCreativeLineItemFindAndReplaceErr', null);
            commit('setLineItemSuccessWithoutRefresh', null);
            commit('setExportLineItemRecord', null);
            commit('setBulkCreativeSuccess', null);
        },
        clearLineitemWithoutRefresh({commit}) {
            commit('setLineItemSuccessWithoutRefresh', null);
        },
        clearAssCreativeFindAndReplace({commit}) {
            commit('setCreativeLineItemFindAndReplace', null);
            commit('setCreativeLineItemFindAndReplaceErr', null);
        },
        clearLineitemSuccess({commit}) {
            commit('setLineitemSuccess', null);
        },
        clearDuplicateLineitemSuccess({commit}) {
            commit('setDuplicateLineitemSuccess', null);
        },
        clearLineitemTarget({commit}) {
            commit('setLineitemTargetingErr', null);
            commit('setLineitemTargetingSuccess', null);
        },
        clearDeleteAssLineItem({commit}) {
            commit('setLineItemAssDeleteStatus', null)
        },
        fetchAdvertiserCreative({commit}, payload) {
            getAdvertiserCreativeListApi(payload).then(res => {
                commit('setAdvertiserCreativeSuccess', res.data)
            })
        },
        fetchCampaignLineItem({commit}, payload) {
            getCampaignLineItemApi(payload).then(res => {
                commit('setLineitemListSuccess', res.data)
            }, (error) => {
                commit('setLineitemErr', error.data)
            })
        },
        deleteLineItem({commit}, payload) {
            deleteLineItemApi(payload).then(res => {
                commit('setLineItemDeleteStatus', res.data)
            }, (error) => {
                commit('setLineItemDeleteStatus', error.data)
            })
        },
        deleteBulkLineItem({commit}, payload) {
            deleteBulkLineItemApi(payload).then(res => {
                commit('setBulkLineItemDeleteStatus', res.data)
            }, (error) => {
                commit('setBulkLineItemDeleteStatus', error.data)
            })
        },
        deleteBulkAssLineItemCreative({commit}, payload) {
            deleteBulkAssLineItemCreativeApi(payload).then(res => {
                commit('setBulkLineItemCreativeDeleteStatus', res.data)
            }, (error) => {
                commit('setBulkLineItemCreativeDeleteStatus', error.data)
            })
        },
        clearDeleteLineItemState({commit}) {
            commit('setLineItemDeleteStatus', null)
            commit('setBulkLineItemDeleteStatus', null)
        },
        clearDeleteLineItemCreativeState({commit}) {
            commit('setBulkLineItemCreativeDeleteStatus', null)
        },
        deleteAssLineItem({commit}, payload) {
            deleteAssLineItemApi(payload).then(res => {
                commit('setLineItemAssDeleteStatus', res.data)
            }, (error) => {
                commit('setLineItemAssDeleteStatus', error.data)
            })
        },
        putAssLineItem({commit}, payload) {
            putAssLineItemApi(payload).then(res => {
                commit('setLineItemAssCreativeUpdateStatus', res.data)
            }, (error) => {
                commit('setLineItemAssCreativeUpdateStatus', error.data)
            })
        },
        postCreativeLineitem({commit}, payload) {
            postCreativeLineitemApi(payload).then(res => {
                commit('setCreativeSuccess', res.data)
            })
        },
        postBulkCreativeLineitem({commit}, payload) {
            postBulkCreativeLineitemApi(payload).then(res => {
                commit('setBulkCreativeSuccess', res.data)
            })
        },
        postCreativeLineItemFindAndReplace({commit}, payload) {
            postCreativeLineItemFindAndReplaceApi(payload).then(res => {
                commit('setCreativeLineItemFindAndReplace', res.data)
            }, (error) => {
                commit('setCreativeLineItemFindAndReplaceErr', error.data)
            })
        },
        getLineitemCreativeList({commit}, payload) {
            getLineitemCreativeListApi(payload).then(res => {
                if (payload.isLineItemFilter) {
                    commit('setCreativeLineItemSuccess', res.data)
                } else {
                    commit('setLineItemCreativeSuccess', res.data)
                }
            })
        },
        getLineitemOurSegmentList({commit}, payload) {
            getLineitemOurSegmentApi(payload).then(res => {
                commit('setLineItemOurSegmentSuccess', {...{results: res.data}, ...payload})
            })
        },
        getLineitemThirdpartySegmentList({commit}, payload) {
            var keyIsExist = payload.keys;
            console.log("keyIsExist", keyIsExist);
            getLineitemThirdpartySegmentApi(payload).then(res => {
                if (payload.isSegmentGroup) {
                    commit('setThirdpartySelectedSegmentGroupSuccess', {...res.data})
                } else if (keyIsExist) {
                    commit('setLineItemThirdpartySelectedSegmentSuccess', {...res.data, ...payload})

                } else {
                    commit('setLineItemThirdpartySegmentSuccess', {...res.data, ...payload})
                }
            })
        },
        getLineitemThirdpartySegmentSearchList({commit}, payload) {
            getLineitemThirdpartySegmentSearchApi(payload).then(res => {
                commit('setLineItemThirdpartySegmentSuccess', {...res.data, ...payload})
            })
        },
        clearLineItemSegment({commit}) {
            commit('setLineItemOurSegmentSuccess', []);
            commit('setLineItemThirdpartySegmentSuccess', {result: []})
            commit('setLineItemThirdpartySelectedSegmentSuccess', {result: []})
            commit('setThirdpartySelectedSegmentGroupSuccess', {result: []})
        },
        getAppNameLookup({commit}, payload) {
          
            getAppNameLookupApi(payload).then(res => {
                commit('setAppNameResponse', res.data)
            }, (error) => {
                commit('setAppNameResponse', [])
            });
        },        

        getAppLookupField({ commit }, payload) {
            console.log('payload.app_lookups_field', payload);
            commit('setLoading', { listType: payload.app_lookups_field, isLoading: true });
            commit('setSearchDataEmpty', { listType: payload.app_lookups_field, isSearchEmpty: false });
            
            getAppLookupFieldApi(payload).then(res => {
                const isEmpty = Array.isArray(res.data.results) && res.data.results.length === 0;
        
                const mutationMap = {
                    'app_name': 'setAppNameResponse',
                    'app_id': 'setAppIdResponse',
                    'app_bundle': 'setAppBundleResponse'
                };
        
                const mutationFunction = mutationMap[payload.app_lookups_field];
        
                if (mutationFunction) {
                    if (isEmpty) {
                        commit('setSearchDataEmpty', { listType: payload.app_lookups_field, isSearchEmpty: true });
                        commit('setLoading', { listType: payload.app_lookups_field, isLoading: false });
                    } else {
                        commit(mutationFunction, res.data);
                    }
                }
            }).catch(error => {
                // Handle errors
                const mutationMap = {
                    'app_name': 'setAppNameResponse',
                    'app_id': 'setAppIdResponse',
                    'app_bundle': 'setAppBundleResponse'
                };
                const errorMutationFunction = mutationMap[payload.app_lookups_field];
        
                if (errorMutationFunction) {
                    commit(errorMutationFunction, []);
                }
            }).finally(() => {
                commit('setLoading', { listType: payload.app_lookups_field, isLoading: false });
            });
        },
        
        fetchProviders({commit}, payload) {
            
            console.log("fetchProviders");
          //  commit('setLoading', { listType: 'provider', isLoading: true });
          //  commit('setSearchDataEmpty', { listType: 'provider', isSearchEmpty: false });
            getProvidersApi(payload).then(res => {
                if (Array.isArray(res.data.results) && res.data.results.length === 0) {
                    // Handle no data found, for example, set the data to an isSearchEmpty true
                   // commit('setSearchDataEmpty', { listType: 'provider', isSearchEmpty: true });
                }
                commit('setProviderData', res.data)
            }, (error) => {
                commit('setProviderData', [])
            }).finally(() => {
                // Set loading to false after the request is completed (success or error)
               // commit('setLoading', { listType: 'provider', isLoading: false });
              });
        },

        getCustomList({commit}, payload) {
            console.log('i am here',payload)
           
           
            
            if (payload.list_type == 'category_categories') {
                if (payload.list_ids) {
                    payload.advertiser_category_ids = payload.list_ids;
                    delete payload.list_ids;
                }
                commit('setLoading', { listType: 'content_category', isLoading: true });
                commit('setSearchDataEmpty', { listType: 'content_category', isSearchEmpty: false });
                getAdvertiserCategory(payload).then(res => {
                    console.log('res.data',res.data)
                    const isEmpty = Array.isArray(res.data) && res.data.length === 0;
                    if (isEmpty) {
                        commit('setSearchDataEmpty', { listType: 'content_category', isSearchEmpty: true });
                    } else {
                        commit('setContentCategoriesData', res.data);
                    }
                }, (error) => {
                    commit('setContentCategoriesData', [])
                }).finally(() => {
                    commit('setLoading', { listType: 'content_category', isLoading: false });
                  });

            } else if (payload.list_type == 'genre') {
                commit('setLoading', { listType: 'genre', isLoading: true });
                commit('setSearchDataEmpty', { listType: 'genre', isSearchEmpty: false });
                getGenreList(payload).then(res => {
                    console.log('res.data',res.data)
                    const isEmpty = Array.isArray(res.data.results) && res.data.results.length === 0;
                    if (isEmpty) {
                        commit('setSearchDataEmpty', { listType: 'genre', isSearchEmpty: true });
                    } else {
                        commit('setGenreData', res.data);
                    }
                }, (error) => {
                    commit('setGenreData', [])
                }).finally(() => {
                    commit('setLoading', { listType: 'genre', isLoading: false });
                  });
            } else {
               
                const loadingMap = {
                    'app_bundle': 'app_bundle_list',
                    'app_id':'app_id_list',
                    'provider':'provider_list',
                    'app_name':'app_name_list',
                    'zip_code':'zip_code_list',
                    'lat_long':'lat_long_list',
                    'channel':'channel_list',
                    
                };
                 
                commit('setLoading', { listType: loadingMap[payload.list_type], isLoading: true });
                commit('setSearchDataEmpty', { listType: loadingMap[payload.list_type], isSearchEmpty: false });
                getCustomListApi(payload).then(res => {
                   
                    const isEmpty = Array.isArray(res.data.results) && res.data.results.length === 0;
                    const mutationMap = {
                        'app_bundle': 'setAppBundleData',
                        'provider': 'setProviderListResponse',
                        'app_name': 'setAppNameListResponse',
                        'app_id': 'setAppIdsData',
                        'deal_id': 'setDealIdsData',
                        'placement_id': 'setPlacementData',
                        'publisher_id': 'setPublisherIdsData',
                        'zip_code': 'setZipCodeData',
                        'lat_long': 'setLatLongData',
                        'channel': 'setChannelListResponse'
                    };

                    const mutationFunction = mutationMap[payload.list_type];
                    if (mutationFunction) {
                        if (isEmpty) {
                            commit('setSearchDataEmpty', { listType: loadingMap[payload.list_type], isSearchEmpty: true });
                        } else {
                            commit(mutationFunction, res.data);
                        }
                    }

                }).catch(error => {
                    // Handle errors
                    const mutationMap = {
                        'app_bundle': 'setAppBundleData',
                        'provider': 'setProviderListResponse',
                        'app_name': 'setAppNameListResponse',
                        'app_id': 'setAppIdsData',
                        'deal_id': 'setDealIdsData',
                        'placement_id': 'setPlacementData',
                        'publisher_id': 'setPublisherIdsData',
                        'zip_code': 'setZipCodeData',
                        'lat_long': 'setLatLongData',
                        'channel': 'setChannelListResponse'
                    };
                    const errorMutationFunction = mutationMap[payload.list_type];

                    if (errorMutationFunction) {
                        commit(errorMutationFunction, []);
                    }
                }).finally(() => {
                    commit('setLoading', { listType: loadingMap[payload.list_type], isLoading: false });
                });
            }

        },
        clearMetaData({commit}) {
            commit('setAppBundleData', null);
            commit('setProviderListResponse',null);
            commit('setChannelListResponse', null);
            commit('setAppNameListResponse',null);
            commit('setAppIdsData', null);
            commit('setDealIdsData', null);
            commit('setPublisherIdsData', null);
            commit('setPlacementData', null);
            commit('setZipCodeData', null);
            commit('setLatLongData', null);

            commit('setOsTypesData', null)

            commit('setCountriesData', null);
            commit('setRegionsData', null);
            commit('setMetrosData', null);
            commit('setCitiesData', null);
            commit('setInventorySourceData', null);
            commit('setLanguagesData', null);
            commit('setContentRatingData', null);
            commit('setPermissionsData', null);
            commit('setTimeZoneData', null);
            commit('setAdPositionData', null);
            commit('setEnvironmentTypeData', null);
            commit('setNativeLayoutData', null);
        },
        getCustomRef({commit}, {url_id, payload}) {
            getRefApi(url_id, payload).then(res => {
                console.log("======", url_id, payload, res)
                if (url_id == 'countries') {
                    console.log("setCountriesData");
                    commit('setCountriesData', res.data)
                }
                if (url_id == 'operating_systems') {
                    let payload = res.data.results.map((item) => {
                        item.name = item.operating_system_name;
                        item.key = item.operating_system_key;
                        return item;
                    });
                    console.log("osVersion_", payload);
                    commit('setOsTypesData', payload)
                }
                if (url_id == 'regions') {
                    commit('setRegionsData', res.data)
                }
                if (url_id == 'metros') {
                    commit('setMetrosData', res.data)
                }
                if (url_id == 'cities') {
                    commit('setCitiesData', res.data)
                }
                if (url_id == 'inventory_sources') {
                    commit('setInventorySourceData', res.data)
                }
                if (url_id == 'languages') {
                    commit('setLanguagesData', res.data)
                }
                if (url_id == 'content_ratings') {
                    commit('setContentRatingData', res.data)
                }
                if (url_id == 'permissions') {
                    commit('setPermissionsData', res.data)
                }
                if (url_id == 'featured-timezones') {
                    commit('setTimeZoneData', res.data)
                }

                if (url_id == 'ad_positions') {
                    res.data.results = res.data.results.map(ele => {
                        ele.key = ele.ad_position_id;
                        ele.name = ele.ad_position_name;
                        return ele;
                    });
                    commit('setAdPositionData', res.data)
                }

                if (url_id == 'environment_types') {
                    res.data.results = res.data.results.map(ele => {
                        ele.key = ele.environment_type_id;
                        ele.name = ele.environment_type_name;
                        return ele;
                    });
                    commit('setEnvironmentTypeData', res.data)
                }

                if (url_id == 'native_layouts') {
                    res.data.results = res.data.results.map(ele => {
                        ele.key = ele.native_layout_id;
                        ele.name = ele.native_layout_name;
                        return ele;
                    });
                    commit('setNativeLayoutData', res.data)
                }

            })
        },
        fetchSingleLineItem({commit}, payload) {
            getLineItemSingleDataApi(payload).then(res => {
                commit('setLineItemSingleDataSuccess', res.data)
            })
        },
        clearIsLineItemNameExist({commit}) {
            commit('setLineItemNameExist', null);
        },
        isLineItemNameExist({commit}, payload) {
            isLineItemNameExistApi(payload).then(res => {
                commit('setLineItemNameExist', res.data)
            }, (error) => {
                commit('setLineItemNameExist', null)
            })
        },
        getRealTimeStat({commit}, payload) {
            getRealTimeStatApi(payload).then(res => {
                commit('setLineItemRealTimeStat', res.data)
            }, (error) => {
                commit('setLineItemRealTimeStat', null)
            })
        },
        checkLineItemDuplicateSwitch({commit}, payload) {
            commit('setLineItemDuplicateSwitch', payload);
        },
        clearLineItemTargetListData({commit}, payload) {
            console.log("payloadpayloadpayloadpayloadpayload", payload);
            commit('setLineItemTargetListDataFlag', payload);
        },
        fetchBiddingStatFlags({commit}, payload) {
            fetchBiddingStatFlagsApi(payload).then(res => {
                commit('setLineItemBiddingStatFlags', res.data)
            }, (error) => {
                commit('setLineItemBiddingStatFlags', null)
            });
        },
        postBulkTargetingLineItem({commit}, payload) {
            postBulkTargetingLineItemApi(payload).then(res => {
                commit('setLineItemBulkTargetingStatus', res.data)
            }, (error) => {
                commit('setLineItemBulkTargetingStatus', null)
            });
        },
        clearBulkTargetingLineItemStatus({commit}, payload) {
            commit('setLineItemBulkTargetingStatus', null)
        },
    };
