/*!
 * Copyright (C) Octillion Media LLC 2019
 * All Rights Reserved.
 */

import { DspAuth } from '@octillion/auth'


export async function authHeader() {
    // return authorization header with jwt token
    const auth = DspAuth.getInstance()
    const user = auth.getUser()
    const item = localStorage.getItem('user')
    if (!item && user && user.isLoggedIn()) {
        const response = { user: user.getDetails() }
        localStorage.setItem('user', JSON.stringify(response));
    }
    return {
        token: await auth.getToken()
    }
}

export function getLoggedInUser() {
    // return authorization header with jwt token
    //return JSON.parse(localStorage.getItem('user'));
    const auth = DspAuth.getInstance()
    const user = auth.getUser()
    const item = localStorage.getItem('user')
    if (item && item !== 'undefined') {
        return JSON.parse(item);
    }
    const response = user ? { user: user.getDetails() } : null;
    if (response) {
        localStorage.setItem('user', JSON.stringify(response));
    }
    return response
}

export function getAccountPlatformObj(isCreated = false, isUpdated = false, onlyPlatformId = false, isUser = false, onlyAccountId = false, accountId = false) {
    // return authorization header with jwt token
    let user = getLoggedInUser()

    if (user && user.user) {
        let reponseObject = { account_id: user.user.account_id, platform_id: user.user.platform_id };

        if (isCreated) {
            reponseObject = { ...reponseObject, ...{ created_by: user.user.user_id } };
        }
        if (isUpdated) {
            reponseObject = { ...reponseObject, ...{ created_by: user.user.user_id, updated_by: user.user.user_id } };
            if (!isCreated) {
                delete reponseObject.created_by;
            }
        }
        if (isUser) {
            reponseObject = { ...reponseObject, ...{ user_id: user.user.user_id } };
        }
        if (onlyPlatformId) {
            reponseObject = { platform_id: user.user.platform_id };
        }
        if (onlyAccountId) {
            reponseObject = { account_id: user.user.account_id };
        }
        if (accountId) {
            reponseObject = { ...reponseObject, ...{ account_id: user.user.account_id } }
        }
        console.log()
        return reponseObject;
    } else {
        return {};
    }
}
